<template>
  <div style="padding: 20px">
    <v-row>
      <v-col cols="6">
        <div class="c-one-title">颜色配置</div>
        <color-picker
            @change="Object.assign(item, $event)"
            @resetColor="item[$event] = o_item[$event]"
            :bg-color="item.bgColor"
            :show-text-color="false"
            :text-color="item.textColor"
            :theme-color="item.themeColor"/>
      </v-col>
    </v-row>
    <div
        style="
        background: #ffffff;
				bottom: 0;
				margin-left: -32px;
				position: fixed;
				width: 100%"
    >
      <div style="height: 1px; background: #e7e7e7; width: 100%;"></div>
      <div style="padding: 15px">
        <v-btn class="mr-4" depressed outlined color="primary" @click="reset">重置</v-btn>
        <v-btn depressed color="primary" @click="save">保存</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import colorPicker from "./colorPicker/index.vue";
export default {
  components: {
    colorPicker
  },
  data() {
    return {
      item: {},
      o_item: {}
    }
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {tab: 2, type: "PC"}).then(res => {
      this.item = res.data.colorStyle
      this.o_item = JSON.parse(JSON.stringify(this.item))
    })
  },
  methods: {
    reset() {
      this.item = this.o_item
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {colorStyle: this.item, type: 'PC'}).then(res=> {
        this.snackbar.success('保存成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin: 8px 0;
}
</style>
